import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TermsOfServiceDto } from '../app/common/dtos/terms-of-service.dto';
import { environment } from '../environments/environment';
import { AuthInfo, LoginService } from '../app/login/login.service';
import { AppService } from '../app/app.service';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Browser } from '@capacitor/browser';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class TermsOfServiceService {
  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
    private loginService: LoginService,
    private appService: AppService,
    private alertController: AlertController,
    private translate: TranslateService
  ) {}

  // ----- CHECK TNCs ACCEPTED VERSION ------ //
  /**
   * 1. Get the latest terms of service available
   * 2. Compare with the user info
   * 3. If user has not accepted latest version, then show a non dismissible popup asking them to agree the terms and conditions. Otherwise, don't do anything
   */
  checkLatestAgainstUser(parsedAuthInfo: AuthInfo) {
    this.getLatest().subscribe({
      next: async (latestTnc) => {
        if (latestTnc) {
          if (
            !this.appService.loggedOut &&
            (!parsedAuthInfo.user.tnc_accepted_version ||
              latestTnc.sk !== parsedAuthInfo.user.tnc_accepted_version)
          ) {
            await this.presentTncAlert(latestTnc);
          } else {
            this.logger.debug(
              `ChargingPage | ionViewDidEnter: User has accepted latest version of tncs`
            );
          }
        } else {
          this.logger.debug(
            `ChargingPage | ionViewDidEnter: No latest Tncs found - user does not have anything to accept`
          );
        }
      },
      error: (error) => {
        // ? If we fail to get the latest tncs, let's ignore this - we will try again the next time they open the charging page
        this.logger.error('Error getting latest TNC', error);
      },
    });
  }

  async presentTncAlert(latestTnc: TermsOfServiceDto) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translate.instant('TERMSOFSERVICE.OUTDATED.TITLE'),
      // subHeader: 'Subtitle',
      message: this.translate.instant('TERMSOFSERVICE.OUTDATED.TEXT'),
      buttons: [
        {
          text: this.translate.instant('TERMSOFSERVICE.OUTDATED.VIEWLATEST'),
          handler: async () => {
            await Browser.open({ url: latestTnc.url });
            return false;
          },
        },
        {
          text: this.translate.instant('TERMSOFSERVICE.OUTDATED.ACCEPT'),
          cssClass: 'cookie-alert-button-ok',
          handler: () => {
            this.updateUserAcceptedVersion(latestTnc.sk).subscribe({
              next: () => {
                this.logger.debug(
                  `ChargingPage | presentTncAlert: User has accepted tncs. Refresh will be triggered`
                );
              },
              error: (error) => {
                // ? If we fail to get the update the user's tncs, let's ignore this - we will try again the next time they open the charging page
                this.logger.error('Error updating user latest TNC', error);
              },
            });
          },
        },
      ],
      backdropDismiss: false,
    });

    alert.onDidDismiss().then(() => {
      this.loginService.refreshLogin().subscribe({
        next: () =>
          this.logger.debug(
            `Triggered one off refresh to verify that they have accepted the latest tnc version`
          ),
        error: (error) => {
          // ? If we fail to refresh, let's ignore this - we will try again the next time they open the charging page
          this.logger.error('Error refreshing user', error);
        },
      });
    });

    alert.present();
  }

  getLatest(): Observable<TermsOfServiceDto> {
    return this.http.get<TermsOfServiceDto>(
      `${environment.captainApiUrl}/terms-and-conditions/latest`,
      { withCredentials: true }
    );
  }

  updateUserAcceptedVersion(version: string): Observable<void> {
    return this.http.patch<void>(
      `${environment.captainApiUrl}/terms-and-conditions/update-user-terms-of-service`,
      { termsOfServiceSk: version },
      { withCredentials: true }
    );
  }
}
