import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class FailedRefreshInterceptor implements HttpInterceptor {
  /**
   * @param {Router} router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private logger: NGXLogger) {}

  // Intercept any outgoing requests and receive their observables
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Handle response
    return next.handle(request).pipe(
      catchError((error) => {
        // Handle response if the exception is 401 and it is not from refresh endpoint, otherwise throw the error
        if (
          error instanceof HttpErrorResponse &&
          request.url === `${environment.apiURL}/auth/refresh` &&
          error.status === 403
        ) {
          this.logger.warn('refresh failed, logging out');
          // Handle the 401 exception error
          this.handleResponseError();
        }
        // Return error if not 401
        this.logger.info(error, 'detected');
        return throwError(error);
      })
    );
  }

  // Function which handles the 401 exception that has been intercepted
  handleResponseError() {
    localStorage.removeItem('authinfo');
  }
}
