import { finalize } from 'rxjs';
import { LoginService } from './login/login.service';
import { RunningSessionsService } from './running-sessions/running-sessions.service';

/**
 * This function is set to fire in the app.module when it initializes.
 * Its purpose is to attempt to refresh the auth token when the application starts up.
 * If the call fails, it starts a timer in the login service to re-attempt the refresh
 * periodically.
 * It also creates the running sessions service. This service periodically calls the captainAPI active-charge-sessions
 * to determine if the user has any charge sessions running.
 * @param loginService An instance of the login service (global)
 * @param runningSessionsService An instance of the running charge session service (global)
 * @returns a promise of void
 */
export function appInitializer(
  loginService: LoginService,
  runningSessionsService: RunningSessionsService
) {
  return () =>
    new Promise<void>((resolve) => {
      runningSessionsService.start();
      // attempt to refresh token on app start up to auto authenticate
      loginService
        .refreshLogin()
        .pipe(
          finalize(() => {
            resolve();
          })
        )
        .subscribe({
          error: () => {
            loginService.startRefreshTokenTimer();
          },
        });
    });
}
