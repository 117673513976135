import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AppService } from '../app.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../login/login.service';
import { ToastController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class RefreshInterceptor implements HttpInterceptor {
  /**
   * @param {Router} router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private logger: NGXLogger,
    private router: Router,
    private toastController: ToastController,
    private appService: AppService,
    private translate: TranslateService,
    private loginService: LoginService
  ) {}

  // Intercept any outgoing requests and receive their observables
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Handle response
    return next.handle(request).pipe(
      catchError((error) => {
        // Handle response if the exception is 401 and it is not from refresh endpoint, otherwise throw the error
        if (
          error instanceof HttpErrorResponse &&
          request.url !== `${environment.apiURL}/auth/refresh` &&
          error.status === 401
        ) {
          this.logger.warn('401 detected');
          // Handle the 401 exception error
          return this.handleResponseError(request, next);
        }
        // Return error if not 401
        this.logger.warn(`${error} detected`);
        return throwError(error);
      })
    );
  }

  // Function which handles the 401 exception that has been intercepted
  handleResponseError(request: any, next:any) {
    // Invalid access token error, refresh the access token and try the request again
    return this.loginService.refreshLogin().pipe(
      switchMap(() => {
        return next.handle(request);
      }),
      // Invalid refresh tokken error, log out the user and return to login page
      catchError(() => {
        this.logger.error('catchError handleResponseError');
        this.loginService.logout();
        this.showSessionEndToast();
        return this.router.navigate(['/auth/login']);
      })
    );
  }

  // Show a toast which lets the user know that their session has
  // expired when the request to refresh the acccess token receives an error
  showSessionEndToast() {
    this.appService.showToast(
      this.translate.instant('REFRESHINTERCEPTOR.ERRORMESSAGE.SESSIONEXPIRED'),
      undefined,
      5000,
      'toastError'
    );
  }
}
