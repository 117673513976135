import { Injectable } from '@angular/core';

import { ToastController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

import { NGXLogger } from 'ngx-logger';

import { TabsService } from './tabs/tabs.service';
import { h } from 'ionicons/dist/types/stencil-public-runtime';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  passwordChangeSuccess = false;

  loggedOut = true;

  iAmOffline = true;

  offlineToast: HTMLIonToastElement | undefined;

  unverifiedToast: HTMLIonToastElement | undefined;

  loginRequiredToast: any;

  constructor(
    private logger: NGXLogger,

    private toastController: ToastController,

    private translate: TranslateService,

    private tabsService: TabsService
  ) {}

  /**

* Description

* -

* - Displays a toast at the bottom of the screen

* - It's message, duration and color are customisable

* @param {string} message The message that you wish to display in the toast

* @param {string} color The background color of the toast

* @param {number} duration The display time of the toast, after which the toast will disappear. It's default behaviour is to never disappear

*/

  async showToast(
    message: string,

    color?: string,

    duration?: number,

    cssClass?: string,

    additionalButton?: ToastButton
  ) {
    if (additionalButton) {
      const toast = await this.toastController.create({
        message,

        position: 'bottom',

        cssClass: cssClass || 'verifyToast',

        color,

        duration,

        buttons: [
          {
            icon: 'close-outline',

            side: 'start',
          },

          additionalButton,
        ],
      });

      await toast.present();
    } else {
      const toast = await this.toastController.create({
        message,

        position: 'bottom',

        cssClass: cssClass || 'verifyToast',

        color,

        duration,

        buttons: [
          {
            icon: 'close-outline',

            side: 'start',
          },
        ],
      });

      await toast.present();
    }
  }

  async showOfflineToast(message: string, cssClass: string, duration?: number) {
    if (this.offlineToast) this.offlineToast.dismiss();

    this.offlineToast = await this.toastController.create({
      message,

      position: 'bottom',

      cssClass: cssClass || 'verifyToast',

      duration,

      buttons: [
        {
          icon: 'close-outline',

          role: 'cancel',

          side: 'start',
        },
      ],
    });

    await this.offlineToast.present();
  }

  dismissOfflineToast() {
    if (this.offlineToast) this.offlineToast.dismiss();
  }

  async showUnverifiedToast(
    message: string,

    color?: string,

    duration?: number,

    cssClass?: string,

    additionalButton?: ToastButton
  ) {
    if (additionalButton) {
      this.unverifiedToast = await this.toastController.create({
        message,

        position: 'bottom',

        cssClass: cssClass || 'verifyToast',

        color,

        duration,

        buttons: [
          {
            icon: 'close-outline',

            side: 'start',
          },

          additionalButton,
        ],
      });

      await this.unverifiedToast.present();
    } else {
      this.unverifiedToast = await this.toastController.create({
        message,

        position: 'bottom',

        cssClass: cssClass || 'verifyToast',

        color,

        duration,

        buttons: [
          {
            icon: 'close-outline',

            side: 'start',
          },
        ],
      });

      await this.unverifiedToast.present();
    }
  }

  // Verify email sent toast

  verifyEmailSentToast() {
    this.translate.get('TABS.CHECKEMAIL').subscribe({
      next: (message) => {
        this.showToast(message, undefined, 10000, 'toastSuccess');
      },

      error: (err) => {
        this.logger.error(err);
      },
    });
  }

  // Verify email sent error toast

  verifyEmailErrorToast() {
    this.translate.get('TABS.SOMETHINGWENTWRONG').subscribe({
      next: (message) => {
        this.showToast(message, undefined, 10000, 'toastError');
      },

      error: (err) => {
        this.logger.error(err);
      },
    });
  }

  // Sends a verify email to the user's inbox and launches a toast to notify the user about it

  sendVerifyEmail() {
    this.tabsService.sendVerifyEmail().subscribe({
      next: () => {
        // show the verifyEmailSentToast

        this.verifyEmailSentToast();
      },

      error: () => {
        this.verifyEmailErrorToast();
      },
    });
  }

  get chargeSessionHistory() {
    return localStorage.getItem('chargeSessionHistory');
  }

  dateToReadable(date: number): string {
    const dateConverted = new Date(date * 1);

    const months = [
      'Jan',

      'Feb',

      'Mar',

      'Apr',

      'May',

      'Jun',

      'Jul',

      'Aug',

      'Sep',

      'Oct',

      'Nov',

      'Dec',
    ];

    const year = dateConverted.getFullYear();

    const month = months[dateConverted.getMonth()];

    const day = dateConverted.getDate();

    return `${day} ${month}, ${year}`;
  }

  timeToReadable(date: number): string {
    const dateConverted = new Date(date * 1);

    const months = [
      'Jan',

      'Feb',

      'Mar',

      'Apr',

      'May',

      'Jun',

      'Jul',

      'Aug',

      'Sep',

      'Oct',

      'Nov',

      'Dec',
    ];

    const year = dateConverted.getFullYear();

    const month = months[dateConverted.getMonth()];

    const day = dateConverted.getDate();

    const hour = dateConverted.getHours();

    let hourString;

    if (hour < 10) {
      hourString = '0' + hour.toString();
    } else {
      hourString = hour.toString();
    }

    const minute = dateConverted.getMinutes();

    let minuteString;

    if (minute < 10) {
      minuteString = '0' + minute.toString();
    } else {
      minuteString = minute.toString();
    }

    return `${day} ${month} ${year}, ${hourString}:${minuteString}`;
  }

  durationToReadable(duration: number, onlyTwoFields = false): string {
    duration = duration / 1000;

    const hours = Math.floor(duration / 3600);

    duration = duration - hours * 3600;

    const minutes = Math.floor(duration / 60);

    const seconds = Math.floor(duration - minutes * 60);

    if (onlyTwoFields) {
      if (hours > 0) {
        return `${hours}h ${minutes}m`;
      } else {
        return `${minutes}m ${seconds}s`;
      }
    }

    return `${hours}h ${minutes}m ${seconds}s`;
  }

  timeRemainingEstimate(duration: number): string {
    duration = duration / 1000;
    const hours = Math.floor(duration / 3600);
    duration = duration - hours * 3600;
    const minutes = Math.floor(duration / 60);

    if (hours > 0) {
      return `< ${hours}h ${minutes}m`;
    }

    if (minutes > 1) {
      return `< ${minutes}m`;
    }

    return `< 1m`;
  }
}

export interface ToastButton {
  text?: string;

  icon?: string;

  side?: 'start' | 'end';

  role?: 'cancel' | string;

  cssClass?: string | string[];

  handler?: () => boolean | void | Promise<boolean | void>;
}
