import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';


import { GoogleMapComponent } from './google-map/google-map.component';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule],
  declarations: [GoogleMapComponent, ShowHidePasswordComponent],
  exports: [GoogleMapComponent, ShowHidePasswordComponent],
})
export class ComponentsModule {}
