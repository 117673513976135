import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AppService, ToastButton } from '../app.service';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';

@Injectable()
/**
 * This class implements the HttpInterceptor interface to intercept HTTP requests and responses
 * and handle 403 Forbidden errors in a centralized place.
 */
export class ForbiddenInterceptor implements HttpInterceptor {

  /**
   * Constructor initializes instances of various services that will be used within this class.
   *
   * @param {NGXLogger} logger - Instance of NGXLogger to log messages.
   * @param {Router} router - Instance of Angular Router service for navigation.
   * @param {AppService} appService - Instance of AppService to handle application-level functionalities.
   * @param {TranslateService} translate - Instance of TranslateService to provide multi-language support.
   */
  constructor(
    private logger: NGXLogger,
    private router: Router,
    private appService: AppService,
    private translate: TranslateService
  ) {}

  /**
   * This method intercepts outgoing HTTP requests, passes them to next handler in the chain and
   * handles any errors that occur during the request processing, particularly 403 forbidden errors.
   *
   * @param {HttpRequest<any>} request - The outgoing HTTP request.
   * @param {HttpHandler} next - The next handler in the chain to pass the request to.
   * 
   * @returns {Observable<any>} - An observable of the (possibly transformed) response.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Passing the request to the next handler in the chain and catching any errors that occur
    return next.handle(request).pipe(
      catchError((error) => {
        // If error is 403 and not from refresh endpoint, handle it, else log and rethrow the error
        if (
          error instanceof HttpErrorResponse &&
          request.url !== `${environment.apiURL}/auth/refresh` &&
          error.status === 403
        ) {
          return this.handleResponseError(request, next, error);
        }
        this.logger.error(error);
        return throwError(error);
      })
    );
  }

  /**
   * Handles 403 errors by determining the error message to display and possibly
   * displaying a toast with additional action buttons.
   *
   * @param {HttpRequest<any>} request - The HTTP request that resulted in the error.
   * @param {HttpHandler} next - The next handler in the chain.
   * @param {HttpErrorResponse} error - The error response object.
   * 
   * @returns {Observable<any>} - An observable that, when subscribed to, shows a toast or navigates to another page.
   */
  handleResponseError(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
    let errorMessage: string;
    let additionnalButton: ToastButton | undefined;

    // Determine the error message and additional button based on the error details
    if (error.error.message && error.error.message === 'UNVERIFIEDEMAIL') {
      errorMessage = 'UNVERIFIEDEMAIL';
      additionnalButton = {
        text: this.translate.instant('SITEVIEWPAGE.BUTTON.VERIFY'),
        role: 'cancel',
        side: 'end',
        handler: () => {
          this.appService.sendVerifyEmail();
        },
      };
    } else {
      errorMessage = 'DEFAULT';
      additionnalButton = undefined;
    }

    // Get the appropriate error message translation and show a toast with the message and possibly an additional button
    return this.translate.get(`COMMON.ERROR_MESSAGES.403.${errorMessage}`).pipe(
      switchMap((message) => {
        if (this.appService.unverifiedToast) this.appService.unverifiedToast.dismiss();
        return this.appService.showUnverifiedToast(message, undefined, 10000, 'toastSuccess', additionnalButton);
      }),
      catchError((err) => {
        // If an error occurs during the error handling, log it and navigate to the charging page
        this.logger.error('Something went wrong', err);
        return this.router.navigate(['/charging']);
      })
    );
  }
}
