import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { concatMap, map, switchMap } from 'rxjs/operators';
import { AppService } from '../app.service';
import { plainToInstance } from 'class-transformer';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { EditProfileService } from '../edit-profile/edit-profile.service';
import { NGXLogger } from 'ngx-logger';

/**
 * The login service provides all the functionality relating to
 * logging a user in
 * logging them out
 * refreshing their login token
 * examining their login status
 */
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private _authInfo: AuthInfo | undefined;
  private refreshTokenTimeout: NodeJS.Timeout | undefined;
  public loginStateChange: BehaviorSubject<boolean>;

  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
    public router: Router,
    private appService: AppService,
    private translate: TranslateService,
    private editProfileService: EditProfileService,
  ) {
    this.loginStateChange = new BehaviorSubject(this.loggedIn);
  }

  public get lastLoggedInAs(): string {
    const lastlogin = localStorage.getItem('lastLoggedInAs');

    if (!lastlogin) return '';

    return lastlogin;
  }

  public set lastLoggedInAs(value: string) {
    localStorage.setItem('lastLoggedInAs', value);
  }

  public set vbevGroupMember(value: boolean) {
    localStorage.setItem('vbevGroupMember', JSON.stringify(value));
  }

  /**
   * The authInfo property shows the information which has been returned when the user
   * logged in, or refreshed their token
   */
  public get authInfo(): AuthInfo | undefined {
    const authInfoString: string | null = localStorage.getItem('authinfo');
    if (authInfoString) {
      this._authInfo = JSON.parse(authInfoString) as AuthInfo;
    }
    return this._authInfo;
  }

  /** This method sets auth info.*/
  public set authInfo(value: AuthInfo | undefined) {
    if (!value) {
      localStorage.removeItem('authinfo');
      this.loginStateChange.next(false);
    } else {
      localStorage.setItem('authinfo', JSON.stringify(value));
      this.loginStateChange.next(true);
    }
    this._authInfo = value;
  }

  // Boolean which indicates if the user is logged in by checking if _authInfo is defined
  public get loggedIn(): boolean {
    if (!this._authInfo) {
      return false;
    } else {
      return true;
    }
  }

  // Boolean which indicates if the user's email is verified by checking if email_verified_date equals to 0 in the _authInfo
  public get emailVerified(): boolean {
    if (!this._authInfo) return false;

    return this._authInfo?.user?.email_verified_date > 0;
  }

  // Date which indicates if the user's mobile is verified by checking if email_verified_date equals to 0 in the _authInfo
  public get mobileVerified(): boolean {
    if (!this._authInfo) return false;

    return this._authInfo?.user?.mobile_verified * 1 > 0;
  }

  /**
   * Description
   * -
   * - Uses the angular httpClient to make a call to the login endpoint of the user api
   * - withCredentials set to true indicates whether or not cross-site Access-Control requests should be made using credentials such as cookies, authorization headers
   * - on success, the AuthInfo is set and a timer starts which refreshes the auth token periodically
   * @param {string} email
   * @param {string} password
   * @returns {Observable} an observable of a user
   */
  login(email: string, password: string) {
    return this.http
      .post<AuthInfo>(
        `${environment.api.authDomain}/login`,
        {
          email,
          password,
        },
        {
          withCredentials: true,
        },
      )
      .pipe(
        map((authInfo) => {
          this.setPropertiesFromAuthInfo(authInfo);
        }),

        concatMap(() => {
          // Call second endpoint after successful login
          return this.http.get<boolean>(
            `${environment.captainApiUrl}/groups/check-membership`,
            {
              withCredentials: true,
              //hardcoding vbev groupname for now
              params: { groupName: 'VBEV' },
            },
          );
        }),
        map((vbevGroupMember) => {
          if (vbevGroupMember) {
            this.vbevGroupMember = vbevGroupMember;
          }
        }),
      );
  }

  /**
   * This method refreshes the auth token by using the refresh tokens (which are in cookies).
   * On success, it sets the auth inof marks the user as logged in, and sets a timer to preiodically refresh
   * the auth token
   * @returns An Observable which returns AuthInfo to the caller
   */
  refreshLogin() {
    return this.http
      .get<AuthInfo>(`${environment.apiURL}/auth/refresh`, {
        withCredentials: true,
      })
      .pipe(
        map((authInfo) => {
          this.setPropertiesFromAuthInfo(authInfo);
        }),
      );
  }

  /**
   * Sets all appropriate properties when new authInfo is obtained
   * @param authInfo The new authInfo to set all the properties from
   */
  setPropertiesFromAuthInfo(authInfo: AuthInfo): void {
    this.authInfo = authInfo;
    this.appService.loggedOut = false;
    this.processRefreshTokenTimer();
    this.lastLoggedInAs = this.authInfo.user.email;
    this.storeLanguage(
      this.authInfo.user.preferred_language.toLocaleLowerCase(),
    );
  }

  /**
   * This calls an endpoint which removes all the cookies from the client.
   * It the wipes out the AuthInfo and stops the refresh timer
   */
  logout() {
    this.http
      .get(`${environment.api.authDomain}/logout`, {
        withCredentials: true,
        responseType: 'text',
      })
      .subscribe();
    localStorage.setItem('user', '');
    localStorage.setItem('chargeSessionHistory', '');
    localStorage.removeItem('vbevGroupMember');
    localStorage.removeItem('chargingServiceMapData');
    this.authInfo = undefined;
    this.appService.loggedOut = true;
    this.stopRefreshTokenTimer();
  }

  /** This method provides a way for the caller to initiate the automatic periodic refresh of the
   * auth token. It is called during application initializer, when the initial attempt to refresh on
   * application startup fails.
   */
  public startRefreshTokenTimer(): void {
    if (this.refreshTokenTimeout) return;

    this.processRefreshTokenTimer();
  }

  /**
   * This method is called every time the refreshTimeout occurs.
   * When called, it calculates a time which is half way between now, and when the auth token is due to expire.
   * It then creates a timeout which fires a request to refresh the users auth token. This call to refreshLoginToken
   * will call this method again if it is successful.
   * If the call fails, then this method calls itself to arrange another attempt halfway between now and expiry.
   * The method stops setting timers if the midway point is less than 10 seconds away.
   */
  private processRefreshTokenTimer(): void {
    this.stopRefreshTokenTimer();
    if (!this.authInfo) return;

    // parse json object from base64 encoded jwt token
    const dateNow = Date.now();
    const accessTokenExpiresTime: Date = new Date(
      this.authInfo.access_token_expiry,
    );
    //console.log(`access token set to expire at ${accessTokenExpiresTime}`);

    const halfWayBetweenNowAndExpiryMs =
      (accessTokenExpiresTime.getTime() - dateNow) / 2;

    if (halfWayBetweenNowAndExpiryMs < 10000) {
      this.logger.debug(
        `refresh timer not set as timeout too soon - ${halfWayBetweenNowAndExpiryMs}`,
      );
      return;
    }

    this.logger.debug(
      `refresh timer set to fire at ${new Date(
        dateNow + halfWayBetweenNowAndExpiryMs,
      ).toISOString()} (in ${halfWayBetweenNowAndExpiryMs} seconds)`,
    );

    this.refreshTokenTimeout = setTimeout(
      () =>
        this.refreshLogin().subscribe({
          error: () => {
            this.processRefreshTokenTimer();
          },
        }),
      halfWayBetweenNowAndExpiryMs,
    );
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  /**
   * This returns the currently selected language for the user.
   * If the language has never been set, it returns 'en' as the default
   * @returns the currently selected language for the user.
   */
  getCurrentLanguage(): string {
    let currentLanguage = localStorage.getItem('appLanguage');

    if (!currentLanguage) {
      currentLanguage = 'en';
      this.storeLanguage('en');
    }

    return currentLanguage;
  }

  private storeLanguage(newLanguage: string) {
    this.logger.info('new language', newLanguage);
    localStorage.setItem('appLanguage', newLanguage);
    this.translate.use(newLanguage);
  }

  /**
   * Changes the current language of the application and, if the user is logged in,
   * it saves the users preferred language to the database
   * @param newLanguage The language to change to ISO_639-1
   */
  public changePreferredLanguage(newLanguage: string) {
    this.storeLanguage(newLanguage);
    if (this.loggedIn) {
      // patch the user
      const body = {
        preferred_language: newLanguage,
      };

      this.editProfileService.updateMyProfile(body).subscribe();
    }
  }
}

export class AuthUser {
  role = '';
  email = '';
  email_verified_date = 0;
  first_name = '';
  last_name = '';
  id = '';
  default_currency = '';
  preferred_language = '';
  address = '';
  region = '';
  mobile_verified = 0;
  tnc_accepted_version = '';
  cookies_accepted = false;
}

/**
 * This class represents the data returns from the auth service whenever you login or refresh
 */
export class AuthInfo {
  user: {
    role: string;
    email: string;
    email_verified_date: number;
    first_name: string;
    last_name: string;
    id: string;
    default_currency: string;
    preferred_language: string;
    address: string;
    region: string;
    mobile_verified: number;
    tnc_accepted_version: string;
    cookies_accepted: boolean;
  };
  access_token_expiry: string;

  constructor(user: any, access_token_expiry: string) {
    this.user = user;
    this.access_token_expiry = access_token_expiry;
  }

  static FromString(json: string): AuthInfo {
    let userJson: Record<string, unknown>;
    let authInfoJson: Record<string, unknown>;

    try {
      userJson = JSON.parse(json).user;
      authInfoJson = JSON.parse(json);
    } catch (err) {
      throw new Error('Could not parse auth info string');
    }
    const instanceOfUser = plainToInstance(AuthUser, userJson);
    const instance = plainToInstance(AuthInfo, authInfoJson);

    const result = instance;
    result.user = instanceOfUser;

    return result;
  }
}
