import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  from,
  of,
  timer,
} from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoginService } from '../login/login.service';
import {
  CapAppMeterValuesResponseDto,
  activeChargeSessionDto,
} from './dtos/active-charge-session.dto';
import { NGXLogger } from 'ngx-logger';
import { EstimatedTimeToSocValue } from './dtos/get-estimated-time-to-soc-value';
import { concatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
/**
 * The running sessions service will fire a request to the captain api to find out if there are any live sessions
 * running.
 * The service only fires timers if the user is logged in.
 */
export class RunningSessionsService {
  // This timer is set to fire every 10 seconds when the user is logged in
  private chargeSessionCheckTimer: Subscription | null = null;
  // This is just a random number to identify this service instance in the logs if required
  private serviceId: number;
  // This behaviour subject will spit out an arrray of active charge sessions when it is updated by the call to the captain api
  public runningChargeSessionSubject: BehaviorSubject<
    activeChargeSessionDto[]
  > = new BehaviorSubject([] as activeChargeSessionDto[]);
  // This subscription is a reference to the login service behaviour subject which. This reports true or false every time the users
  // login status changes. This event is used to turn the internal timer on and off
  private loginStateChangeSubscription: Subscription;
  // The key to store the last successful running charges data in local storage
  private LOCAL_STORAGE_KEY = 'runningSessionsData';
  private TIMER_INTERVAL_MS = 10000;

  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
    private loginService: LoginService,
  ) {
    this.serviceId = Math.floor(Math.round(Math.random() * 1000000));
    // subscribe to the login service to be alerted when the login state changes
    this.loginStateChangeSubscription = loginService.loginStateChange.subscribe(
      {
        next: (newState) => this.loginStateChanged(newState),
      },
    );
    //this.logger.debug(`${this.serviceId}: constructor`);
  }

  /**
   * When the login state changes, start / stop the timer
   * @param newState The new logged in state - true is logged in
   * @returns nothing
   */
  private loginStateChanged(newState: boolean): void {
    if (newState) {
      this.start();
      return;
    }
    this.stop();
  }

  /**
   * Start the timer to fire immediately, then every TIMER_INTERVAL_MS milliseconds
   */
  public start(): void {
    if (this.chargeSessionCheckTimer === null) {
      this.chargeSessionCheckTimer = timer(0, this.TIMER_INTERVAL_MS).subscribe(
        {
          next: () => {
            this.updateActiveChargeSessions();
          },
        },
      );
    }
  }

  /**
   * Sets the interval between running session checks. Only works when the user is logged in, otherwise the
   * call is ignored
   * @param newInterval The new interval in ms. Send null to reset the interval to default values. Min value is 2000.
   */
  public setTimerIntervalMs(newInterval?: number) {
    if (this.loginService.loggedIn) {
      if (!newInterval) {
        newInterval = this.TIMER_INTERVAL_MS;
      }

      if (newInterval < 2000) {
        newInterval = 2000;
      }

      //this.logger.debug(`setTimerIntervalMs:${newInterval}`);
      if (
        this.chargeSessionCheckTimer &&
        !this.chargeSessionCheckTimer.closed
      ) {
        this.chargeSessionCheckTimer.unsubscribe();
      }

      this.chargeSessionCheckTimer = timer(0, newInterval).subscribe({
        next: () => {
          this.updateActiveChargeSessions();
        },
      });
    }
  }

  /**
   * Stop the timer from firing and clear all local data - the user is no longer logged in
   */
  public stop(): void {
    if (this.chargeSessionCheckTimer) {
      this.chargeSessionCheckTimer.unsubscribe();
      this.chargeSessionCheckTimer = null;
      localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify([]));
      this.runningChargeSessionSubject.next([]);
    }
  }

  /**
   * This is called whenever the timer fires.
   * It will make a call to the captain api to attempt to get a list of running charge sessions
   * It wires up success and error functions accordingly
   */
  private updateActiveChargeSessions() {
    //this.logger.debug(`${this.serviceId}: timer fired`);
    if (this.loginService.loggedIn) {
      this.http
        .get<activeChargeSessionDto[]>(
          `${environment.captainApiUrl}/chargesessions/active-charge-sessions`,
          {
            withCredentials: true,
          },
        )
        .subscribe({
          next: (data) => {
            let estimatesIndex = 0;
            from(data)
              .pipe(
                concatMap((el) =>
                  this.getChargeSessionTimeToSocEstimates(el.chargeSessionId),
                ),
              )
              .subscribe({
                next: (estimatedTimeData: EstimatedTimeToSocValue[]) => {
                  data[estimatesIndex].estimatesValues = estimatedTimeData;
                  for (let i = 0; estimatedTimeData.length > i; i++) {
                    if (estimatedTimeData[i].soc == 80) {
                      data[estimatesIndex].estimatedTimeTo80 =
                        estimatedTimeData[i];
                    }
                    if (estimatedTimeData[i].soc == 100) {
                      data[estimatesIndex].estimatedTimeTo100 =
                        estimatedTimeData[i];
                    }
                  }
                  estimatesIndex++;
                },
                complete: () => {
                  this.onNextSessionData(data);
                },
              });
          },
          error: () => this.returnOfflineData(),
        });
    } else {
      //this.logger.debug(`${this.serviceId}: not logged in`);
      this.stop();
    }
  }

  /** This will be fired when the http client fires the 'next' method. The data received
   * will be from the body of the response. We store the data in localStorage and then
   * pass the data to the subscribers by firing the 'next' method
   */
  private onNextSessionData(data: activeChargeSessionDto[]): void {
    //this.logger.debug(`${this.serviceId} : on session data`);

    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(data));
    this.runningChargeSessionSubject.next(data);
    return;
  }

  /**
   * This is called whenever there is an error getting the data from the captain api
   * It tries to load the last good data from the localstorage and returns that instead
   */
  private returnOfflineData(): void {
    //this.logger.debug(`${this.serviceId} : offline data`);
    let localData: string | null = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    let offlineSessions: activeChargeSessionDto[] = [];

    try {
      if (!localData) {
        localData = '[]';
      }

      offlineSessions = JSON.parse(localData) as activeChargeSessionDto[];
    } catch {
      offlineSessions = [];
    }

    this.runningChargeSessionSubject.next(offlineSessions);
  }

  public getMeterValuesByTransactionId(
    transactionId: number,
  ): Observable<CapAppMeterValuesResponseDto | null> {
    if (this.loginService.loggedIn) {
      return this.http.get<CapAppMeterValuesResponseDto>(
        `${environment.captainApiUrl}/chargesessions/charge-session-meter-values/${transactionId}`,
        {
          withCredentials: true,
        },
      );
    } else {
      //this.logger.debug(`${this.serviceId}: not logged in`);
      this.stop();
      return of(null);
    }
  }

  public getChargeSessionTimeToSocEstimates(
    id: string,
  ): Observable<EstimatedTimeToSocValue[]> {
    if (this.loginService.loggedIn) {
      return this.http.get<EstimatedTimeToSocValue[]>(
        `${environment.captainApiUrl}/chargesessions/charge-session-time-to-soc-estimates/${id}`,
        {
          withCredentials: true,
        },
      );
    } else {
      //this.logger.debug(`${this.serviceId}: not logged in`);
      this.stop();
      return of([] as EstimatedTimeToSocValue[]);
    }
  }
}
