import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'auth',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: 'auth/reset-password/:token',
    loadChildren: () =>
      import('./password-reset/password-reset.module').then(
        (m) => m.PasswordResetModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'page-not-found',
    loadChildren: () =>
      import('./page-not-found/page-not-found.module').then(
        (m) => m.PageNotFoundModule
      ),
  },
  {
    path: 'verify-email',
    loadChildren: () =>
      import('./verify-email/verify-email.module').then(
        (m) => m.VerifyEmailModule,
      ),
  },
  {
    path: 'verify-mobile',
    loadChildren: () =>
      import('./verify-mobile/verify-mobile.module').then(
        (m) => m.VerifyMobileModule,
      ),
  },
  {
    path: 'change-email',
    loadChildren: () =>
      import('./change-email/change-email.module').then(
        (m) => m.ChangeEmailModule,
      ),
  },
  {
    path: 'delete-account-confirm/:delete_token',
    loadChildren: () =>
      import('./delete-account-confirm/delete-account-confirm.module').then(
        (m) => m.DeleteAccountConfirmModule,
      ),
  },
  {
    path: 'chargestation-information',
    loadChildren: () =>
      import(
        './charge-station-information/charge-station-information.module'
      ).then((m) => m.ChargeStationInformationModule),
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // This value is required for server-side rendering to work.
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
