import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TabsService {
  constructor(private http: HttpClient) {}

  getUserData() {
    return this.http.get(`${environment.apiURL}/cap/getMyProfile`, { withCredentials: true });
  }

  sendVerifyEmail() {
    return this.http.get(`${environment.apiURL}/auth/emailVerificationJob`, {
      withCredentials: true,
      responseType: 'text',
    });
  }
}
