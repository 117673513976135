import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Browser } from '@capacitor/browser';
import { environment } from '../../environments/environment';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class VersionCheckerService {
  shouldIUpdateInbox = new Subject<number>();

  constructor(
    private logger: NGXLogger,
    private alertController: AlertController,
    private translate: TranslateService
  ) {
    this.shouldIUpdateInbox.subscribe({
      next: (v: number) => {
        this.presentAlert(v);
      },
    });
  }

  async presentAlert(v?: number) {
    let translationPrefix: string;
    let dismissible: boolean;

    let handler1;

    // if there is a deadline, the user should be told about an upcoming update
    if (v && v < 0) {
      translationPrefix = 'UPDATECHECKER.ALERT.MANDATORY';
      handler1 = function () {
        Browser.open({ url: `${environment.appUrl}` });
        return false;
      };
      dismissible = false;
    } else {
      translationPrefix = 'UPDATECHECKER.ALERT.OPTIONAL';
      handler1 = function () {
        return;
      };
      dismissible = true;
    }
    let dateToShow = 'now';

    if (v) {
      dateToShow = this.dateToReadable(v);
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translate.instant(`${translationPrefix}.HEADER`),
      // subHeader: 'Subtitle',
      backdropDismiss: dismissible,
      message: this.translate.instant(`${translationPrefix}.MESSAGE`, {
        deadline: dateToShow,
      }),
      buttons: [
        {
          text: this.translate.instant(`${translationPrefix}.BUTTON2`),
          handler: handler1,
        },
        // {
        //   text: this.translate.instant(`${translationPrefix}.BUTTON1`),
        //   handler: () => {
        //     this.logger.info('going to the app store');
        //   },
        // },
      ],
    });

    await alert.present();
  }

  dateToReadable(date: number): string {
    const dateConverted = new Date(date * 1);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const year = dateConverted.getFullYear();
    const month = months[dateConverted.getMonth()];
    const day = dateConverted.getDate();
    return `${day} ${month}, ${year}`;
  }
}
