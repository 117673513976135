<ng-content></ng-content>
<a class="type-toggle" (click)="toggleShow()">
  <ion-icon
    class="show-option"
    *ngIf="showPassword"
    name="eye-off-outline"
  ></ion-icon>
  <ion-icon
    class="hide-option"
    *ngIf="!showPassword"
    name="eye-outline"
  ></ion-icon>
  <!-- In case you want to use text instead of icons -->
  <!--
	<span class="show-option" [hidden]="showPassword">show</span>
	<span class="hide-option" [hidden]="!showPassword">hide</span>
	-->
</a>
