export const environment = {
  production: true,
  apiURL: 'https://userapi.aqua-superpower.com:8082',
  captainApiUrl: 'https://captainapi.aqua-superpower.com:9000',
  appUrl: 'https://app.aqua-superpower.com',
  loggerLevel: 7,
  api: {
    apiKey: 'AIzaSyC4JTvKlINGYty6LjOY-ZmF8ti4Sn8E7qM',
    authDomain: 'https://userapi.aqua-superpower.com:8082/auth',
    userDomain: 'https://userapi.aqua-superpower.com:8082/users',
    projectId: 'aquasuperpower',
  },
  appShellConfig: {
    debug: false,
    networkDelay: 0,
  },
  siteViewEnabled: false,
  chargeSessionGraphEnabled: true,
  aquaLinkEnabled: true,
};
