import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { VersionCheckerService } from './version-checker.service';

@Injectable()
export class MethodNotAllowedInterceptor implements HttpInterceptor {
  /**
   * @param {Router} router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private versionCheckerService: VersionCheckerService) {}

  // Intercept any outgoing requests and receive their observables
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Handle response
    return next.handle(request).pipe(
      catchError((error) => {
        // Handle response if the exception is 401 and it is not from refresh endpoint, otherwise throw the error
        if (
          error instanceof HttpErrorResponse &&
          request.url !== `${environment.apiURL}/auth/refresh` &&
          error.status === 405
        ) {
          // Handle the 405 exception error
          this.handleResponseError();
        }
        // Return error if not 403
        return throwError(error);
      })
    );
  }

  // Function which handles the 405 exception that has been intercepted
  handleResponseError() {
    this.versionCheckerService.shouldIUpdateInbox.next(-1);
  }
}
