import { Component } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from './login/login.service';
import { HistoryHelperService } from './utils/history-helper.service';
import { VersionCheckerService } from './utils/version-checker.service';
import { Device } from '@capacitor/device';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { versionNumber } from '../version-number';
import { StatusBar, StatusBarStyle, Style } from '@capacitor/status-bar';
import { isPlatform, Platform } from '@ionic/angular';
import { App } from '@capacitor/app';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NGXLogger } from 'ngx-logger';
import { Capacitor } from '@capacitor/core';
import { register } from 'swiper/element/bundle';

// register the swiper component globally - see https://ionicframework.com/docs/ja/v6/angular/slides
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  textDir = 'ltr';

  // Inject HistoryHelperService in the app.components.ts so its available app-wide
  constructor(
    private logger: NGXLogger,
    public translate: TranslateService,
    public historyHelper: HistoryHelperService,
    private loginService: LoginService,
    private versionCheckerService: VersionCheckerService,
    private http: HttpClient,
    private platform: Platform,
    private router: Router,
    private location: Location
  ) {
    this.initializeApp();
    loginService.changePreferredLanguage(loginService.getCurrentLanguage());
  }

  async initializeApp() {
    this.logger.info('App not running in production');
    try {
      await SplashScreen.hide();
    } catch (err) {
      this.logger.error('This is normal in a browser', err);
    }

    if (isPlatform('mobile')) {
      StatusBar.setStyle({ style: Style.Dark });
    }

    const info = await Device.getInfo();
    // only perform a version check if we are on a native app
    if (info.platform !== 'web') {
      this.checkVersion().subscribe({
        next: (data) => {
          // check if there is no update
          if (data === 'ok') {
            this.logger.info('no update to be downloaded');
          } else if (isNaN(parseInt(data))) {
            this.logger.error('date returned cannot be parsed as number');
          } else {
            // if there is an update to do, show alert and convert result into a number
            this.versionCheckerService.shouldIUpdateInbox.next(parseInt(data));
          }
        },
        error: (err) => {
          this.logger.error('something has gone wrong');
        },
      });
    }

    // Subscribe to the input of a back button, if it is clicked then check the current route
    // and performs actions accordingly. If router url is /charging then quit the app. If router
    // url is /auth/login, /support or /profile then navigate to charging. If it is anything else
    // then move back to the previous url in the router.
    this.platform.backButton.subscribeWithPriority(10, () => {
      if (this.router.url === '/auth/login' || this.router.url === '/support' || this.router.url === '/profile') {
        const platform = Capacitor.getPlatform();
        if (platform === 'web') {
          this.router.navigate(['/charging']);
        }
      }

      if (this.router.url === '/charging') {
        App.exitApp();
      }

      if (
        this.router.url !== '/charging' &&
        this.router.url !== '/auth/login' &&
        this.router.url !== '/support' &&
        this.router.url !== '/profile'
      ) {
        this.location.back();
      }
    });
  }

  checkVersion() {
    this.logger.info('checking if app is out of date');
    return this.http.post(
      `${environment.captainApiUrl}/version`,
      { versionNumber },
      {
        withCredentials: true,
        responseType: 'text',
      }
    );
  }
}
