import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
/**
 * The HistoryHelperService class helps in tracking the URL navigation history within the app.
 * It stores the URL of the previously visited page, which can be useful in various scenarios
 * such as navigating back to the previous page, tracking user navigation, etc.
 */
export class HistoryHelperService {
  
  // A string variable to hold the URL of the previously visited page.
  previousUrl: string = '';

  /**
   * Constructor of the HistoryHelperService class.
   *
   * @param logger - An instance of NGXLogger for logging purposes.
   * @param router - An instance of Angular Router service to subscribe to router events.
   */
  constructor(private logger: NGXLogger, private router: Router) {

    // Subscribing to router events and filtering only the NavigationEnd events.
    // NavigationEnd represents an event triggered when a navigation ends successfully.
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        
        // Casting the event object to NavigationEnd type to access the urlAfterRedirects property.
        // Then, storing the URL after redirects (if any) to the previousUrl property.
        const navEvent = event as NavigationEnd;
        this.previousUrl = navEvent.urlAfterRedirects;
      });
  }
}

